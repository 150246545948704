
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// import image1 from "assets/images/";
import MDAlert from "components/MDAlert";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import chatgptimage from "assets/images/chatgpt.png"
// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 PRO React examples
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import GridContainer from "components/Grid/GridContainer.js";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Autocomplete from "@mui/material/Autocomplete";
import NumPad from 'react-numpad';
import {
  
  TextField,
  
} from "@material-ui/core";
import Divider from "@mui/material/Divider";
import { ClientJS } from 'clientjs';
import DefaultItem from "examples/Items/DefaultItem";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Firebase from "firebase";
import moment from "moment";
import { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from '@material-ui/core/DialogContentText';
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

import MDBadge from "components/MDBadge";
import pattern from "assets/images/illustrations/pattern-tree.svg";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";



// Material Dashboard 2 PRO React examples
const firebaseConfig = {
  apiKey: "AIzaSyDSaYuHlpFznfk_nhTcitcoGG-8QUGK1ec",
  authDomain: "onroad3-e593b.firebaseapp.com",
  databaseURL: "https://onroad3-e593b-default-rtdb.firebaseio.com",
  projectId: "onroad3-e593b",
  storageBucket: "onroad3-e593b.appspot.com",
  messagingSenderId: "587064425563",
}
Firebase.initializeApp(firebaseConfig);
const removeEmpty = (obj) => {
  let newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === Object(obj[key])) newObj[key] = removeEmpty(obj[key]);
    else if (obj[key] !== undefined) newObj[key] = obj[key];
  });
  return newObj;
};

const phoneKeyPad = ["abc","def","ghi","jkl","mno", "pqrs", "tuv","wxyz"]

function Home() {
  const urlPathBranchName = window?.location?.pathname.slice(1)
  const storeBranchString = atob(urlPathBranchName)
  var urlStoreEmail = storeBranchString.substring(0, storeBranchString.lastIndexOf(","))
  var urlBranch = storeBranchString.substring(storeBranchString.lastIndexOf(",")+1, storeBranchString.length);;
  const [singnedIn, setSignedIn] = useState(false);
  const [liveClock, setLiveClock] = useState(false);
  const [checkInDialog, setCheckInDialog] = useState(false);
  const [checkoutDialog, setCheckoutDialog] = useState(false);
  
  const [activeTeamMembers, setActiveTeamMembers] = useState([])
  const [allCheckdInMemmbers, setAllCheckdInMemmbers] = useState([])
  const [allCheckedinAndCheckedoutMemmbers, setAllCheckedinAndCheckedoutMemmbers] = useState([])
  const [storeEmail, setStoreEmail] = useState(urlStoreEmail)
  const [branchName, setBranchName] = useState(urlBranch)
  const [branchCode, setBranchCode] = useState()
  
  const [currentStore, setCurrentStore] = useState()
  const [storeLoginError, setStoreLoginError] = useState()

  const [checkInMember, setCheckInMember] = useState()
  const [checkinPasscode, setCheckinPasscode] = useState()
  const [isPasscodeValid, setIsPasscodeValid] = useState(false)
  const [passcodeValidationMessage, setPasscodeValidationMessage] = useState()
  const [checkOutMember, setCheckOutMember] = useState()
  const [checkOutPasscode, setCheckOutPasscode] = useState()

  const handleCheckin = async () => {
    if (!checkInMember) {
      return
    }
    const phoneandName = checkInMember.split("Phone: ")

    for (let index = 0; index < activeTeamMembers.length; index++) {
      const element = activeTeamMembers[index];
      if (element.phone == phoneandName[1]) {
        if (element.passcode == checkinPasscode) {
          setPasscodeValidationMessage(null)
          setCheckInMember(null);
          setCheckinPasscode("");
          Firebase.database()
          .ref(`/tracking/timecard/checkincheckouts/${currentStore.storeEmail.replace(/\./g, ",")}/${moment().format("DD-MM-YYYY")}/${currentStore.branchid}/${phoneandName[1]}`)
          .once('value', snapshot => {
            let val = snapshot.val()
            if (val) {
              // update
              let tmpshift = val.shifthours
              const currentIndex = tmpshift.push({checkin: moment().format("hh:mm A")}) - 1 
              snapshot.ref.update({
                ischeckedin: true,
                ischeckedout: false,
                name: phoneandName[0],
                phone: phoneandName[1],
                checkin: moment().format("hh:mm A"),
                branch: {
                  name: currentStore.branchName,
                  address: currentStore.branchAddress,
                },
                currentcheckinshifthour: currentIndex,
                shifthours: tmpshift
              })
            } else {
              // first time
              snapshot.ref.update({
                ischeckedin: true,
                ischeckedout: false,
                name: phoneandName[0],
                phone: phoneandName[1],
                checkin: moment().format("hh:mm A"),
                branch: {
                  name: currentStore.branchName,
                  address: currentStore.branchAddress,
                },
                currentcheckinshifthour: 0,
                shifthours: [{checkin: moment().format("hh:mm A")}]
              })
            }
          })

          
          // Firebase.database().ref(`/tracking/timecard/checkincheckouts/${currentStore.storeEmail.replace(/\./g, ",")}/${moment().format("DD-MM-YYYY")}/${currentStore.branchid}/${phoneandName[1]}`).set({
          //   ischeckedin: true,
          //   ischeckedout: false,
          //   name: phoneandName[0],
          //   phone: phoneandName[1],
          //   checkin: moment().format("hh:mm A"),
          //   branch: {
          //     name: currentStore.branchName,
          //     address: currentStore.branchAddress,
          //   }
          // })
          
          setCheckInDialog(false); 

        } else {
          setPasscodeValidationMessage("Invalid Passcode, Check-In Failed")
        }
        break
      }
    }
    
  }

  const handleLogout = () => {
    setCurrentStore(null)
    Firebase.database().ref(`/timecardstorelogin/${storeEmail.replace(/\./g, ",")}/${branchName}/`).update({ islogedin: false })
  }
  const handleLogin = async () => {
    const timeCardReportRef = await Firebase.database().ref(`/timecardstorelogin/${storeEmail.replace(/\./g, ",")}/${branchName}/`).once('value')
    const branch = timeCardReportRef.val()
    if (branch && branch.passcode.toString().toLowerCase() == branchCode.toLowerCase()) {
      // await localStorage.setItem("onroad-store", `${storeEmail}|${branchName}|${branchCode}`)
      setCurrentStore({ storeEmail: storeEmail, branchName: branchName, branchCode: branchCode, branchid: branch.id, branchAddress: branch.address })
      Firebase.database().ref(`/timecardstorelogin/${storeEmail.replace(/\./g, ",")}/${branchName}/`).update({ islogedin: true })

      setStoreLoginError(false)
      setStoreEmail(null)
      setBranchName(null)
      setBranchCode(null)
    } else {
      setStoreLoginError(true)
    }
  }
  useEffect(() => {
      if(singnedIn){
        return;
      }
      Firebase.auth().signInAnonymously()
      .then(() => {
        setSignedIn(true)
      })
      .catch((error) => {
        setSignedIn(false)
        var errorCode = error.code;
        var errorMessage = error.message;
      });
  }, []);

  useEffect(async () => {
    if(!singnedIn){
      return;
    }
    if (!urlStoreEmail || !urlBranch) {
      return
    }
    const storeDetails = await Firebase.database().ref(`/timecardstorelogin/${urlStoreEmail}/${urlBranch}/`).once('value')
    const branch = storeDetails.val()
    if (branch && branch.islogedin) {
      // await localStorage.setItem("onroad-store", `${storeEmail}|${branchName}|${branchCode}`)
      setCurrentStore({ storeEmail: urlStoreEmail, branchName: urlBranch, branchCode:  branch.passcode, branchid: branch.id, branchAddress: branch.address })
      setStoreLoginError(false) 
    }
  }, [singnedIn]);

  useEffect(async () => {
    if(!singnedIn){
      return;
    }
 
  }, [singnedIn]);


  useEffect(async () => {
    if(!singnedIn){
      return;
    }
    const todayDate = moment().format("DD-MM-YYYY").toString()
    localStorage.setItem('onroad-logindate', todayDate)
    // const loginDate = await localStorage.getItem('onroad-logindate');
    // if (loginDate) {
    //   localStorage.removeItem('onroad-logindate')
    // } else {
    //   localStorage.setItem('onroad-logindate', todayDate)
    // }
    
  }, [singnedIn]);

  

  useEffect(() => {
    setInterval(() => {setLiveClock(moment().format("DD MMMM YYYY hh:mm:ss A"))}, 1000)
  }, []);

  // const passcode = (name, phone) => {
  //   let result = ""
  //   for (var i = 0; i < name.slice(0, 4).length; i++) {
  //     result.concat(phoneKeyPad.findIndex(i => i.includes(first4chars[i])) + 2)
  //   }
  //   result.concat(phone.slice(checkInMember.length - 4))
  //  return result
  
  // }

  useEffect(() => {
    if (!currentStore) {
      return
    }
    const onChildAdd = Firebase.database()
    .ref(`/tracking/timecard/teammembers/${currentStore.storeEmail.replace(/\./g, ",")}/`)
    .on('value', snapshot => {
      const data = snapshot.val()
      if (data) {
        let result = []
        const keys = Object.keys(data);
        for (let index = keys.length - 1; index > -1; index--) {
          const element = data[keys[index]];
          if (element && element.status && (element.branch && element.branch.some(x => x.branchname.toLowerCase() == branchName.toLowerCase()))) {
            result.push({ name: element.name, phone: element.phone, passcode: element.password })
          }
        }
        setActiveTeamMembers(result);
      }
    });
    return () => Firebase.database().ref(`/tracking/timecard/teammembers/${currentStore.storeEmail.replace(/\./g, ",")}/`).off('value', onChildAdd);
    
  }, [currentStore])

  // useEffect(async () => {
  //   if (!activeTeamMembers || (activeTeamMembers && activeTeamMembers.length == 0)) {
  //     return
  //   }
  //   const timeCardReportRef = await Firebase.database().ref(`/tracking/timecard/checkincheckouts/${currentStore.storeEmail.replace(/\./g, ",")}/${moment().format("DD-MM-YYYY")}/`).once('value')
  //   const timeCardReport = timeCardReportRef.val()
  //   if (timeCardReport) {
  //     return
  //   } else {
  //     let result = {}
  //     for (let index = 0; index < activeTeamMembers.length; index++) {
  //       const element = activeTeamMembers[index];
  //       result[element.phone] = {
  //         branch: {
  //           name: currentStore.branchName,
  //           addres: currentStore.branchAddress
  //         },
  //         name:element.name
  //       }
  //     }
  //     Firebase.database().ref(`/tracking/timecard/checkincheckouts/${currentStore.storeEmail.replace(/\./g, ",")}/${moment().format("DD-MM-YYYY")}/`).set(result)
  //   }
    
  // }, [activeTeamMembers])

  

  useEffect(() => {
    if (!currentStore) {
      return
    }
    const onChildAdd = Firebase.database()
      .ref(`/tracking/timecard/checkincheckouts/${currentStore.storeEmail.replace(/\./g, ",")}/${moment().format("DD-MM-YYYY").toString()}/${currentStore.branchid}/`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          let checkdInMemmbers = []
          let checkinAndcheckout = []
          const keys = Object.keys(data);
          for (let index = keys.length - 1; index > -1; index--) {
            const element = data[keys[index]];

            if (element && element.ischeckedin && !element.ischeckedout) {
              checkdInMemmbers.push({ name: element.name, phone: element.phone, date: element.date, checkintime: element.checkin })
            }
            if (element && element.ischeckedin) {
              checkinAndcheckout.push({ name: element.name, phone: element.phone, date: element.date, checkintime: element.checkin })
            }

          }
          setAllCheckedinAndCheckedoutMemmbers(checkinAndcheckout)
          setAllCheckdInMemmbers(checkdInMemmbers);
        }
      });
    return () => Firebase.database().ref(`/tracking/timecard/checkincheckouts/${currentStore.storeEmail.replace(/\./g, ",")}/${moment().format("DD-MM-YYYY").toString()}/${currentStore.branchid}/`).off('value', onChildAdd);
  }, [currentStore])



  const storeSignInScreen = () => {
    return (
      <>
        <div></div>
        <BasicLayout image={bgImage} style={{ padding: "0px" }}>
         
      <Card style={{padding: "0px"}}>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Store Login
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
                  <MDInput type="text" label="Store" value={storeEmail} fullWidth onChange={(e) => {
                    e.preventDefault();
                    setStoreEmail(e.target.value)
                    
                   }} />
            </MDBox>
            <MDBox mb={2}>
                  <MDInput type="text" label="Branch Name" value={branchName} fullWidth onChange={(e) => {
                    e.preventDefault();
                    setBranchName(e.target.value)
              }}/>
            </MDBox>
            <MDBox mb={2}>
                  <MDInput type="password" label="Password" fullWidtho onChange={(e) => {
                    e.preventDefault();
                    setBranchCode(e.target.value)
              } } />
            </MDBox>
            
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleLogin}>
                Login
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
      </>

    )

  }
  const instructionsCard = () => {
    return (<></>)
  }
  
  const brandingCard = () => {
    return (
      <>
        <MDAlert color="success" dismissible>
        <Icon fontSize="large">notifications</Icon>&nbsp;
        <MDBox >
        <Grid container>
          <Grid item xs={12}>
          <MDBox  display="flex" alignItems="center">
            {/* <MDAvatar alt="Avatar" size='xl' variant="rounded" src={}>
            </MDAvatar> */}
            
              <MDBox ml={2} mt={0.5} lineHeight={1.4} >
                <MDTypography variant="body2" color="white"> 
                      <b>Input: </b>When the keyboard appears, use the microphone button to input text
                      <br />
                      <b>Audio while driving: </b>If audio is not playing while driving, open the FM app and start playing audio. Afterward, switch the audio source to Bluetooth or another option and close the FM app. Open the web browser and press the play button to start playback.
              </MDTypography>
              </MDBox>
            </MDBox>            
            </Grid>
          </Grid>
          
         </MDBox>
      
        </MDAlert>
        <br />
      </>

    )
  }

  const renderItems = allCheckdInMemmbers.map(({name, phone}, key) => (
    <MDBox
      key={name}
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      borderRadius="lg"
      py={1}
      pr={2}
    >
      <MDBox display="flex" alignItems="center">
        
        <MDBox display="flex" flexDirection="column">
          <MDTypography variant="button" fontWeight="medium" gutterBottom>
            {name}
          </MDTypography>
          {/* <MDTypography variant="caption" color="text">
            {phone}
          </MDTypography> */}
        </MDBox>
      </MDBox>
      <MDBox display="flex">
        <MDTypography
          
          variant="button"
          
         
          sx={{
            lineHeight: 0,
            transition: "all 0.2s cubic-bezier(.34,1.61,.7,1.3)",
            p: 0.5,

            "&:hover, &:focus": {
              transform: "translateX(5px)",
            },
          }}
        >
          {/* <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon> */}
          <MDButton size="small" variant="contained" color="primary" style={{fontSize: "10px"}}  container onClick={() => { setCheckoutDialog(true);  setCheckOutMember(`${name} Phone: ${phone}`)}} >Check-out </MDButton>

        </MDTypography>
      </MDBox>
    </MDBox>
  ));


  const handleCheckout = () => {
    if (!checkOutMember) {
      return;
    }
    const phoneandName = checkOutMember.split("Phone: ")
    for (let index = 0; index < activeTeamMembers.length; index++) {
      const element = activeTeamMembers[index];
      if (element.phone == phoneandName[1]) {
        if (element.passcode == checkOutPasscode) {
          setPasscodeValidationMessage(null)
          setCheckOutMember(null);
          setCheckOutPasscode("");
          
          // Firebase.database().ref(`/tracking/timecard/checkincheckouts/${currentStore.storeEmail.replace(/\./g, ",")}/${moment().format("DD-MM-YYYY")}/${currentStore.branchid}/${phoneandName[1]}/`).update({
          //   ischeckedout: true,          
          //   checkout: moment().format("hh:mm A"),
          // })
          

          Firebase.database()
          .ref(`/tracking/timecard/checkincheckouts/${currentStore.storeEmail.replace(/\./g, ",")}/${moment().format("DD-MM-YYYY")}/${currentStore.branchid}/${phoneandName[1]}`)
          .once('value', snapshot => {
            let val = snapshot.val()
            if (val) {
              // update
              if (val.shifthours && val.shifthours[val.currentcheckinshifthour]) {
                val.shifthours[val.currentcheckinshifthour]["checkout"] = moment().format("hh:mm A")
                snapshot.ref.update({
                  ischeckedin: false,
                  ischeckedout: true,
                  checkout: moment().format("hh:mm A"),
                  currentcheckinshifthour: null,
                  shifthours: val.shifthours
                })
              }
            }
          })

          setCheckoutDialog(false); 

        } else {
          setPasscodeValidationMessage("Invalid Passcode, Check-Out Failed")
        }
        break
      }
    }
    

 
  }
  const searchFilters = () => {
    if (activeTeamMembers && activeTeamMembers.length > 0) {
      let result = []
      for (let index = 0; index < activeTeamMembers.length; index++) {
        const element = activeTeamMembers[index];
        if (
          !allCheckdInMemmbers.find((member) => member.phone == element.phone)
          ) {
          result.push(`${element.name} Phone: ${element.phone}`)
        }
      }
      return result
    }
    return []
  }  
  const checkinScreenCard = () => {
  

    return (
      <>
      <Dialog open={checkInDialog}  aria-labelledby="form-dialog-title" fullWidth style={{height: "90vh"}}>
          <DialogTitle id="form-dialog-title">Pick Your Name From The List
            
          </DialogTitle>
          <DialogContent>   
            <Autocomplete
              options={searchFilters()}
              getOptionLabel={(option) => option.split("Phone: ")[0]}
              onChange={(event, newValue) => {
                setCheckInMember(newValue)
              }}
              renderInput={(params) => <MDInput {...params} variant="standard" label="Search your name"  />}
            />
            <br />
            <DialogTitle id="form-dialog-title" style={{textAlign: "center"}}>Check-in Time: {moment().format("DD MMMM YYYY hh:mm A").toString()}
            
          </DialogTitle>
         
            <div>
              <MDTypography color="gray" style={{ fontSize: "20px", textAlign: "center", color: "gray" }}>
                {passcodeValidationMessage}
              {/* Passcode {isPasscodeValid ? (<> <MDBadge variant="contained" color="success" badgeContent="Valid" container /></>) : (<><MDBadge variant="contained" color="primary" badgeContent="Not Valid or Empty" container /></>) } */}
            </MDTypography>
              
            <MDTypography color="gray" style={{ fontSize: "20px", textAlign: "center", color: "gray" }}>
              <NumPad.Number
                // inline={true}
                value={checkinPasscode}
                onChange={(value) =>
                {
                  setPasscodeValidationMessage(null)
                  setCheckinPasscode(value)
                }
                  }
                decimal={false}
                placeholder={"Please Enter Passcode"}
              >
                </NumPad.Number>
              {/* Passcode {isPasscodeValid ? (<> <MDBadge variant="contained" color="success" badgeContent="Valid" container /></>) : (<><MDBadge variant="contained" color="primary" badgeContent="Not Valid or Empty" container /></>) } */}
            </MDTypography>
              
             
          </div>
          
            {/* {checkInMember ? (<><MDTypography  color="white" style={{fontSize: "40px"}}>
              Name: {checkInMember}
              <br />
              Time: {moment().format("HH:mm")}
                          </MDTypography></>) : ( <div style={{ height: "150px" }}></div>)}
	 */}
            <div style={{ height: "50px" }}></div>
            <p style={{ color: "grey", padding: "10px", fontSize: "15px" }}>If your name not showing, Ask the manager to add you into the system</p>

          </DialogContent>
          <DialogActions>
            <MDButton onClick={() => { setCheckInMember(null); setPasscodeValidationMessage(null);  setCheckinPasscode(""); setCheckInDialog(false)}} color="primary">
              Cancel
            </MDButton>
             <MDButton onClick={() => { handleCheckin()}} color="success" disabled={!checkinPasscode || !checkInMember}>
              Check-in
            </MDButton>
          </DialogActions>
      </Dialog>
      
      <Dialog open={checkoutDialog}  aria-labelledby="form-dialog-title" fullWidth style={{height: "90vh"}}>
          <DialogTitle id="form-dialog-title">Check Out
            
          </DialogTitle>
      <DialogContent>   
         
            <MDTypography  color="gray" style={{fontSize: "20px", textAlign: "center", color: "gray"}}>
              {checkOutMember}
            </MDTypography>
            <br />
            <DialogTitle id="form-dialog-title" style={{fontSize: "20px", textAlign: "center", color: "gray"}}>Check-out Time: {moment().format("DD MMMM YYYY HH:mm A").toString()}
            
          </DialogTitle>
         
            <div>
            <MDTypography  color="gray" style={{fontSize: "20px", textAlign: "center", color: "gray"}}>
             {passcodeValidationMessage}
              </MDTypography>
              
              <MDTypography  color="gray" style={{fontSize: "20px", textAlign: "center", color: "gray"}}>
              <NumPad.Number
                // inline={true}
                value={checkOutPasscode}
                onChange={value => {                 
                  setCheckOutPasscode(value)
                }}
                  decimal={false}
                  placeholder={"Please Enter Passcode"}
              >
                </NumPad.Number>
            </MDTypography>
              
              
          </div>
          
            {/* {checkInMember ? (<><MDTypography  color="white" style={{fontSize: "40px"}}>
              Name: {checkInMember}
              <br />
              Time: {moment().format("HH:mm")}
                          </MDTypography></>) : ( <div style={{ height: "150px" }}></div>)}
	 */}
            <div style={{ height: "50px" }}></div>
            <p style={{ color: "grey", padding: "10px", fontSize: "15px" }}>Dont reveal the passcode, Ask your manager if you want to reset passcode</p>

          </DialogContent>
          <DialogActions>
            <MDButton onClick={() => { setCheckOutPasscode(""); setCheckOutMember(null); setPasscodeValidationMessage(null); setCheckoutDialog(false)}} color="primary">
            Cancel
          </MDButton>
            <MDButton onClick={() => { handleCheckout()}} color="success" disable={!checkinPasscode}>
            Check-Out
          </MDButton>
            </DialogActions>
          
          
  
      </Dialog>
        
      <MDBox my={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Card
              sx={({ palette: { gradients }, functions: { linearGradient }, boxShadows: { xl } }) => ({
                background: linearGradient(gradients.dark.main, gradients.dark.state),
                boxShadow: xl,
                position: "relative",
                          })}
                          style={{height: "90vh"}}
            >
            <MDBox
              position="absolute"
              top={0}
              left={0}
              width="100%"
              height="100%"
              opacity={0.2}
              sx={{
                backgroundImage: `url(${bgImage})`,
                backgroundSize: "cover",
              }}
                      />
          <MDBox position="relative" zIndex={2} p={2}>
          <MDBox display="flex" justifyContent="space-between" alignItems="center">
            <MDBox display="flex" alignItems="center">
              <MDBox mr={3} lineHeight={1} style={{marginTop: "33%"}}>
                <MDTypography variant="button" color="white" style={{fontSize: "40px"}}>
                {liveClock}
                            </MDTypography>
                            <br />
                            <MDBadge variant="contained" color="success" badgeContent={currentStore?.branchName} style={{padding: "0px", left: "-6px"}} />
                            <br />
                <MDTypography variant="button" color="white" style={{fontSize: "20px"}}>
                {currentStore?.branchAddress}
                            </MDTypography>
                            <br />
                          
                          
                <MDTypography variant="button" color="white" style={{fontSize: "20px"}}>
                            <MDButton variant="text" color="error" onClick={() => { handleLogout() }} style={{padding: "0px", left: "-6px"}}>
                  Logout
                </MDButton>   
                </MDTypography>
                
              </MDBox>
              {/* <MDBox mr={3} lineHeight={1} style={{marginTop: "92%"}}>
                    
              </MDBox> */}
            </MDBox>
            
          </MDBox>
        </MDBox>
              
  {/*                 
                      <MDBox mt={40}  mb={1} lineHeight={0}>
                    <MDTypography variant="h3" fontWeight="bold">
                      {moment().format("DD MMMM YYYY HH:mm:ss A")}
                    </MDTypography>
                  </MDBox>
                  <MDBadge variant="contained" color="success" badgeContent="Store Is Active" container />
                </MDBox> */}
              </Card>  
            </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
              <MDButton
                
                variant="gradient"
                  color="success"
                  style={{ left: "31%" }}
                  onClick={() => {setCheckInDialog(true)}}
              >
                {"Check-In"}&nbsp;
                <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                  </MDButton>
                  <br />
                <div style={{height: "35px"}}></div>
              <Card styl={{ minHeight: "1000px", paddingTop: "20px" }}>
                
              <MDBox p={3} >
                <MDTypography variant="body2" color="text">
                  Check-In Members
                </MDTypography>
                    <MDBox mt={2} mb={1} lineHeight={0}>
                      {allCheckdInMemmbers &&  allCheckdInMemmbers.length > 0 ? renderItems : (<>  <MDTypography  color="gray" style={{fontSize: "20px", textAlign: "center", color: "gray"}}>
             No Check-In Members Yet
                          </MDTypography></>) }
                </MDBox>
              </MDBox>
            </Card> 
            </Grid>
          </Grid>
        </MDBox>
       
      </MDBox>
      </>
    )
  }


  return (
    <>
      {/* {brandingCard()} */}
      {currentStore ? checkinScreenCard() : storeSignInScreen()}
      {/* {checkinScreenCard()} */}
      
    </>
  );
return (<></>);
}

export default Home;
